<template>
  <div class="body">
    <div class="form">
      <h1>运维管理系统</h1>
      <div class="txtb">
        <label for="">账号:</label>
        <input type="text" v-model="formData.name" placeholder="请输入账号" />
      </div>
      <div class="txtb">
        <label for="">密码:</label>
        <input
          type="password"
          v-model="formData.pwd"
          placeholder="请输入密码"
        />
      </div>
      <span class="btn" @click="handleSubmit">登录</span>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import axios from "axios";
// const BASE_URL81 =
//   process.env.NODE_ENV === "production"
//     ? "http://118.190.39.21:8081"
//     : "/81api";
const BASE_URL81 =
  process.env.NODE_ENV === "production"
    ? "http://119.45.30.192:20080"
    : "/81api";
export default {
  data() {
    return {
      formData: {
        name: "",
        pwd: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      let _this = this;
      let params = {
        account: _this.formData.name,
        password: _this.formData.pwd,
      };
      axios
        .request({
          url: BASE_URL81 + "/ego/vpn-sso/user/agent/login",
          method: "post",
          data: params,
        })
        .then((res) => {
          if (res.data.code === 200) {
            sessionStorage.setItem("eman", _this.formData.name);
            sessionStorage.setItem("drowssap", _this.formData.pwd);
            sessionStorage.setItem("token", res.data.data.token);

            _this.$router.push({
              name: "Users",
            });
          } else {
            ElMessage.error(res.data.message);
          }
        })
        .catch(() => {
          ElMessage.error("服务器出小差啦~请联系管理员！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
/*渐变背景样式*/
.body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    125deg,
    #abbde3,
    #4f77d6,
    #0099fe,
    #0166fe,
    #7050bf,
    #ed9daa
  );
  background-size: 400%;
  font-family: "montserrat";
  animation: bganimation 15s infinite;
}

/*表单样式*/
.form {
  width: 85%;
  max-width: 600px;
  /* max-height: 700px; */
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 5px #000;
  text-align: center;
  font-family: "微软雅黑";
  color: #fff;
}

/*表单标题样式*/
.form h1 {
  margin-top: 0;
  font-weight: 200;
}

.form .txtb {
  border: 1px solid #aaa;
  margin: 8px 0;
  padding: 12px 18px;
  border-radius: 10px;
}

.txtb label {
  display: block;
  text-align: left;
  color: #fff;
  font-size: 14px;
}

/*姓名 手机 邮箱 备注框样式*/
.txtb input,
.txtb textarea {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  margin-top: 6px;
  font-size: 18px;
  color: #fff;
}

/*备注框样式*/
.txtb textarea {
  max-height: 300px;
}

/*提交按钮样式*/
.btn {
  display: block;
  /* background-color: rgba(156, 39, 176, .5); */
  padding: 14px 0;
  color: #fff;
  cursor: pointer;
  margin-top: 8px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 10px;
}
</style>
